<template>
  <section class="pa-3">
    <div class="d-flex align-start flex-wrap">
      <h2 class="mb-6 mr-8">ユーザー一覧</h2>
      <v-btn dark large rounded color="#2196F3" :to="{ name: 'users_create' }">
        <v-icon dark class="mr-3"> mdi-account-outline </v-icon>
        新規登録
      </v-btn>
    </div>

    <div class="d-flex justify-end">
      <div class="col-12 col-md-4">
        <v-text-field
          :value="params.words"
          type="search"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          height="44px"
          @change="
            (value) => {
              params.words = value;
              params.page = 1;
            }
          "
        />
      </div>
    </div>

    <section v-if="users.length !== 0 && pageLength >= params.page">
      <v-data-table
        :headers="headers"
        :items="users"
        :page.sync="params.page"
        :items-per-page="perPage"
        :search="params.words"
        :custom-filter="search"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        no-results-text="データがありません。"
        hide-default-footer
        @page-count="pageCount = $event"
        @update:sort-desc="updateSort($event)"
      >
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'users_edit',
              params: { userId: item.id, from: params },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:item.role="{ item }">
          {{ item.role.label }}
        </template>
      </v-data-table>
      <v-pagination v-model="params.page" :length="pageCount" />
    </section>

    <!-- usersがない場合、usersがないページを指定した場合、ロード中用 -->
    <section v-if="users.length === 0 || pageLength < params.page">
      <v-data-table
        :headers="headers"
        :items="[]"
        :loading="loading"
        :loading-text="'ロード中...'"
        :no-data-text="'データがありません。'"
        hide-default-footer
      />
    </section>
  </section>
</template>

<script>
import { getData } from "../../axios";

export default {
  name: "Index",

  data() {
    return {
      loading: true,
      params: {
        page: 1,
        words: "",
        id: "",
      },
      sortBy: [],
      sortDesc: [],
      pageCount: 0, // 検索後のpaginationとの紐づけの為
      headers: [
        { text: "ID", align: "left", sortable: true, value: "id" },
        { text: "名前", align: "left", sortable: false, value: "name" },
        {
          text: "メールアドレス",
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: "権限ステータス",
          align: "left",
          sortable: false,
          value: "role",
        },
      ],
      users: [],
    };
  },

  computed: {
    // pageCountと別にpageの長さを計算
    pageLength() {
      return Math.ceil(this.users.length / this.perPage);
    },
    perPage() {
      // 初期値: 10
      if (this.$store.getters["auth/client"].setting) {
        return Number(this.$store.getters["auth/client"].setting.perPage) || 10;
      } else {
        return 10;
      }
    },
  },

  watch: {
    $route(newValue, oldValue) {
      if (this.$route.fullPath === "/users") {
        this.params.page = 1;
        this.params.id = "";
        this.params.words = "";
      } else if (Number(newValue.query.page) !== Number(oldValue.query.page)) {
        this.params.page = Number(newValue.query.page);
        this.params.id = newValue.query.id || "";
        this.params.words = newValue.query.words || "";
      }
    },
    params: {
      handler() {
        if (this.paramsQueryDifferent()) {
          this.routerPush();
          window.document.documentElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.$route.query.page) {
      this.params.page = Number(this.$route.query.page);
    }
    if (this.$route.query.words) {
      this.params.words = this.$route.query.words;
    }
    if (this.$route.query.id) {
      this.params.id = this.$route.query.id;
      this.sortBy[0] = "id";
      this.sortDesc[0] = this.$route.query.id === "desc" ? true : false;
    }
    this.getUsers();
  },

  methods: {
    updateSort(event) {
      if (event.length === 0) {
        this.params.id = "";
      } else {
        this.params.id = event[0] ? "desc" : "asc";
      }
    },
    routerPush() {
      const params = Object.assign({}, this.params);
      // 空文字のクエリーを無くす：例）words=''
      Object.keys(params).forEach((key) => {
        if (params[key] === "") delete params[key];
      });
      this.$router.push({ query: params });
    },
    paramsQueryDifferent() {
      // paramsとqueryで違う項目がある場合はtrue
      // query.xxxがundefinedの場合は空文字で比較
      return Boolean(
        Number(this.params.page) !== Number(this.$route.query.page) ||
          this.params.words !== (this.$route.query.words || "") ||
          this.params.id !== (this.$route.query.id || "")
      );
    },
    getUsers() {
      this.loading = true;
      getData("users")
        .then((res) => {
          this.users = res.data;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    search(_, search, item) {
      const regList = search.split(/\s+/).map((str) => new RegExp(str));
      return regList.every((reg) => {
        return Object.keys(item).some((key) => reg.test(item[key]));
      });
    },
  },
};
</script>
