var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pa-3"},[_c('div',{staticClass:"d-flex align-start flex-wrap"},[_c('h2',{staticClass:"mb-6 mr-8"},[_vm._v("ユーザー一覧")]),_c('v-btn',{attrs:{"dark":"","large":"","rounded":"","color":"#2196F3","to":{ name: 'users_create' }}},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v(" mdi-account-outline ")]),_vm._v(" 新規登録 ")],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('v-text-field',{attrs:{"value":_vm.params.words,"type":"search","outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","height":"44px"},on:{"change":(value) => {
            _vm.params.words = value;
            _vm.params.page = 1;
          }}})],1)]),(_vm.users.length !== 0 && _vm.pageLength >= _vm.params.page)?_c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"page":_vm.params.page,"items-per-page":_vm.perPage,"search":_vm.params.words,"custom-filter":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-results-text":"データがありません。","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.params, "page", $event)},"page-count":function($event){_vm.pageCount = $event},"update:sort-desc":function($event){return _vm.updateSort($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'users_edit',
            params: { userId: item.id, from: _vm.params },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.role",fn:function({ item }){return [_vm._v(" "+_vm._s(item.role.label)+" ")]}}],null,false,4178801339)}),_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1):_vm._e(),(_vm.users.length === 0 || _vm.pageLength < _vm.params.page)?_c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":[],"loading":_vm.loading,"loading-text":'ロード中...',"no-data-text":'データがありません。',"hide-default-footer":""}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }